<template>
  <div>
    <vx-card>
      <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" :actions="actions"  @on-export-csv="onExportCsv">

        <template slot="global_search-after" >
          <div class="ml-4 mt-2 flex">
            <vs-switch color="success" v-model="showAllMails" @change="fetchData()"/> <p class="ml-2">Alle Mails anzeigen (incl. den bearbeiteten) </p>
          </div>
        </template>

        <template slot="row_actions" slot-scope="props">
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="8">
              <vx-tooltip text="Importieren in Beleg-Management" class="w-1" position="left" >
                <vs-button color="success" type="filled" icon="check_circle" size="small"
                           @click="clickTransferMailToDocuments(props.row.id)" :disabled="props.row.finish_processed == 1">Import</vs-button>
              </vx-tooltip>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="2">
              <vx-tooltip text="Löschen" class="w-1" position="left">
                <vs-button color="dark" type="filled" icon="delete" size="small"
                           @click="deleteMail(props.row.id)" :disabled="props.row.finish_processed == 1"></vs-button>
              </vx-tooltip>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="2">
              <vx-tooltip text="Als erledigt markieren" class="w-1" position="left" v-if="props.row.finish_processed == 0">
                <vs-button color="dark" type="filled" icon="check" size="small"
                           @click="clickCheckAsFinished(props.row.id)" ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Als 'zu erledigen' markieren" class="w-1" position="close" v-if="props.row.finish_processed == 1">
                <vs-button color="dark" type="filled" icon="close" size="small"
                           @click="clickCheckAsNotFinished(props.row.id)" ></vs-button>
              </vx-tooltip>
            </vs-col>

          </vs-row>

        </template>
        <template slot="mail_content" slot-scope="props">
          <span class="vbt-table-td link" @click="showMailContentPopup(props.row)">Inhalt anzeigen</span>
        </template>

        <template slot="download_file" slot-scope="props">
            <span class="vbt-table-td" v-if="props.row.attachments_array">
              <div v-for="(attachment, index) in props.row.attachments_array">
                <a :href="attachment" target="_blank">Anhang #{{index+1}} - {{ attachment | subStr}} </a>  <vs-icon icon="close" class="red pointer" @click="deleteAttachment(props.row,index)"></vs-icon><br>
              </div>
            </span>
        </template>

        <template slot="empty-results">
          Keine Überweisungen im nächsten Rechnungslauf vorgemerkt.
        </template>
      </vue-bootstrap4-table>
    </vx-card>
    <mysql-selection-popup :active="activeMysqlSelectionPrompt"
                           @close="onMysqlSelectionPromptClose" @accept="fetchData"></mysql-selection-popup>

    <vs-popup class="holamundo" :title="activeMailPopupData.mail_subject" :active.sync="activeMailPopup">
      <div v-html="activeMailPopupData.mail_content"></div>
    </vs-popup>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../api";

import moment from 'moment';
import qs from "qs";
import {mapGetters} from "vuex";
import QueryHelper from "@/mixins/helper/query.helper";
import PriceHelper from "@/mixins/helper/price.helper";
import MysqlSelectionPopup from "../../components/mysql-connection-popup/MysqlSelectionPopup";
import RowColumn from "@/plugins/vue-bootstrap4-table-master/src/components/RowColumn";

export default {
  components: {
    RowColumn,
    VueBootstrap4Table,
    MysqlSelectionPopup
  },
  data() {
    return {
      activeMailPopupData: [],
      activeMailPopup: false,
      showAllMails: false,
      activeMysqlSelectionPrompt: false,
      data: [],
      actions: [

        {
          btn_text: "SEPA XML Export",
          event_name: "on-export-csv",
        }

      ],
      columns: [
        {
          label: "ID",
          name: "id",
          filter: {
            type: "simple",
            placeholder: "ID suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          width:'3%'
        },
        {
          label: "Absender",
          name: "from_name",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Absender Mail",
          name: "from_mail",
          filter: {
            type: "simple",
            placeholder: "Absender suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left'
        }, {
          label: "Erstellt am",
          name: "created_at",
          formatter: this.formatDate,
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left'
        },
        {
          label: "Betreff",
          name: "mail_subject",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "E-Mail Inhalt",
          name: "mail_content",
          slot_name: "mail_content",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Anhänge",
          name: "original_filename",
          slot_name: 'download_file',
          sort: true,
          filter: {
            type: "simple",
            placeholder: "Dokumentenname suchen..."
          },
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          width: '13%'
        },

        {
          label: "Actions",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'actions-column',
          width: '13%'
        }],
      config: {
        checkbox_rows: false,
        rows_selectable: true,
        page: 1,
        per_page: 50,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8'
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 30,
        page: 1,
      },
      documentQueryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 100,
        page: 1,
      },

    }
  },
  created() {
    if(!this.userHasPermission('view_bank_transaction_documents')) {
      this.$vs.notify({
        title: 'Keine Berechtigung',
        text: 'Für diese Seite hast du keine Berechtigung',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
      this.$router.push(`/`);
    }
    if(this.userHasPermission('view_bank_transaction_documents')) {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(['userHasPermission']),
    allMails(){
      return this.showAllMails;
    },
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);
    },
    requestDocumentParams() {
      let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);

      if(this.showAllMails === false) {
        queryParams.filter.push({
          field: 'finish_processed',
          value: '0',
          expression: 'exact'
        });
      }
      queryParams.order =
          {
            'field': 'created_at',
            'direction': 'desc'
          }
      ;

      return queryParams;
    },

  },
  methods: {
    dateFilterValidator(column, value) {
    },
    fetchData() {
      this.$vs.loading()

      let bankTransactionsDocumentsPromise = ApiService.get('bill-mails', {
        params: this.requestDocumentParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });

      Promise.all([bankTransactionsDocumentsPromise])
          .then(([bankTransactionsDocuments]) => {
            this.data = bankTransactionsDocuments.data.result;
            this.$vs.loading.close();
          }).catch((response) => {

        this.$vs.loading.close();
        this.activeMysqlSelectionPrompt = true;

      });
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onMysqlSelectionPromptClose() {
      this.activeMysqlSelectionPrompt = false;
      this.fetchData();
    },
    onExportCsv(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'SEPA XML Download',
        text: 'Bist du dir sicher, dass du die aktuelle SEPA XML downloaden möchtest? Du musst wissen, das alle Überweisungen dann auf erledigt gesetzt werden und in der nächsten SEPA-Datei nicht mehr erscheinen.',
        accept: this.downloadSepaXML,
        acceptText: 'Ja, SEPA XML generieren',
        cancelText: 'Abbrechen'
      })
    },
    downloadSepaXML(){
      ApiService.get('bank-transactions/document/billing-run',{ responseType: 'blob' }).then(response => {

        let blob = new Blob([response.data], { type: 'application/xml' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'mediagraphik-sepa.xml'
        link.click()

      })
    },
    clickCheckAsNotFinished(mailId){
      this.updateBillMailFinishedStatus(mailId,0)
    },
    clickCheckAsFinished(mailId){
      this.updateBillMailFinishedStatus(mailId,1)
    },
    updateBillMailFinishedStatus(mailId,finish_processed = 0){

      ApiService.put('bill-mails/' + mailId,{finish_processed: finish_processed})
          .then(response => {
            if (response.data.status === "success") {
              this.AddPopUpActive = false
              this.fetchData()
              this.$vs.notify({
                title: 'Erfolgreich',
                text: 'E-Mail erfolgreich bearbeitet',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }
          })
          .catch((response) => {
            this.$vs.notify({
              title: 'Fehler',
              text: response.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
    },
    deleteMail(mailId){
      ApiService.delete('bill-mails/' + mailId)
          .then(response => {
            if (response.data.status === "success") {
              this.AddPopUpActive = false
              this.fetchData()
              this.$vs.notify({
                title: 'Erfolgreich',
                text: 'E-Mail gelöscht',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }
          })
          .catch((response) => {
            this.$vs.notify({
              title: 'Fehler',
              text: response.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
    },
    clickTransferMailToDocuments(mailId){
      this.$vs.loading();
      ApiService.get('bill-mails/transfer-to-documents/' + mailId)
          .then((response) => {
            if (response.data.status === 'success') {
              this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Die E-Mail Anhänge wurden erfolgreich ins Dokumentenmanagement überführt',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })

              this.fetchData();
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehler',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            this.$vs.loading.close();
          })

    },
    showMailContentPopup(mail){
      this.activeMailPopupData = mail;
      this.activeMailPopup = true;
    },
    deleteAttachment(mail,index){
      let payload = { delete_attachment_index: index};

      ApiService.put('bill-mails/' + mail.id, payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetchData();
              return this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Erfolgreich entfernt',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }

            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Fehler',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Fehler',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
    }
  },
  filters: {
    jsonPretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
    subStr: function(string) {
      return string.slice(string.length - 4);
    }
  },
  mounted() {
  }
}

</script>


<style lang="scss">
.vs-icon.red {
  color: #e24733;
  font-weight: 900;
}

.vs-icon.pointer {cursor: pointer;}

.vs-icon.pointer:hover {
  color: black;
}
table.table tr {
  border-bottom: 1px solid #e5e5e5;
}
</style>